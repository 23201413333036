import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Subscription, forkJoin } from 'rxjs';

// Storage

import { take } from 'rxjs/operators';
import { CarroService } from 'src/app/_services/carro.service';
import { CorredorService } from 'src/app/_services/corredor.service';
import { DevolucionRiderService } from 'src/app/_services/devolucion-rider.service';
import { DevolucionService } from 'src/app/_services/devolucion.service';
import { RiderService } from 'src/app/_services/rider.service';
import { SolicitudCambioCategoriaRiderService } from 'src/app/_services/solicitud-cambio-categoria-rider.service';
import { SolicitudCambioCategoriaService } from 'src/app/_services/solicitud-cambio-categoria.service';
import { TareaService } from 'src/app/_services/tarea.service';
import { EncryptService } from 'src/app/_shared/encrypt';
import { LocalStorageService } from 'src/app/_shared/storage';
import { WidgetModalContainerComponent } from 'src/app/components/widget-modal-container/widget-modal-container.component';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
  providers: []
})
export class DotsComponent implements OnInit, OnDestroy {

  // declaraciones
  public notificationList = [];

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public loading = false;
  @ViewChild(WidgetModalContainerComponent, { static: false }) widgetModalContainerComponent: WidgetModalContainerComponent;
  private notificacionSubscription: Subscription;

  constructor(
    private tareaService: TareaService,
    private solicitudCambioCategoriaService: SolicitudCambioCategoriaService,
    private solicitudCambioCategoriaRiderService: SolicitudCambioCategoriaRiderService,

    private router: Router,
    private localStorageAs: LocalStorageService,
    private encryptService: EncryptService,

    private devolucionService: DevolucionService,
    private devolucionRiderService: DevolucionRiderService,

    private corredorService: CorredorService,
    private riderService: RiderService,

    private carroService: CarroService,

  ) {

  }


  ngOnInit() {
    this.getDataNotification();

    this.localStorageAs.watch('tareas').subscribe(tareas => {
      if (tareas !== null) {
        this.notificationList = JSON.parse(tareas);
      }
    });

    this.notificacionSubscription = this.carroService.getNotificacionTareasObservable()
      .subscribe(() => {
        this.getDataNotification();
      });
  }


  ngOnDestroy() {
    if (this.notificacionSubscription) {
      this.notificacionSubscription.unsubscribe();
    }
  }

  getDataNotification() {
    this.loading = true;

    const notifications = this.tareaService.obtenerTareasConLimite(10);

    const petitionsNotifications = forkJoin([notifications]);
    petitionsNotifications
      .pipe(take(1))
      .subscribe(
        (res) => {

          this.localStorageAs.set('tareas', JSON.stringify(res[0]));

          this.loading = false;
        },
        err => {
          console.log(err);
          this.loading = false;

        });
  }

  obtenerNotificacionPorTipo(tipo: string) {
    return this.notificationList.filter((notif) => notif.tipo === tipo);
  }

  toggleButton(event) {
    if (event) {
      this.getDataNotification();
    }

  }

  clickTarea(tarea) {
    const timestamp = new Date().getTime();


    switch (tarea.tipo) {
      case 'SOLICITUD_CAMBIO_CATEGORIA':
        this.loading = true;

        const obtenerSolicitudPorId = tarea.plataforma === 'BK' ? this.solicitudCambioCategoriaService.obtenerSolicitudPorId(tarea.id) :
          this.solicitudCambioCategoriaRiderService.obtenerSolicitudPorId(tarea.id);

        const petitions = forkJoin([obtenerSolicitudPorId]);
        petitions
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.router.navigate([tarea.plataforma === 'BK' ? '/solicitudes-cambio-categoria/list' : '/solicitudes-cambio-categoria-rider/list'], {
                queryParams: {
                  timestamp: timestamp,
                  data: this.encryptService.encryptData(res[0])
                }
              });
              this.loading = false;
            },
            err => {
              console.log(err);
              this.loading = false;

            });

        break;

      case 'SOLICITUD_DEVOLUCION':
        this.loading = true;

        const obtenerDevolucion = tarea.plataforma === 'BK' ?  this.devolucionService.obtenerDevolucion(tarea.id):
        this.devolucionRiderService.obtenerDevolucion(tarea.id);

        const petitionsDevolucion = forkJoin([obtenerDevolucion]);
        petitionsDevolucion
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.router.navigate([tarea.plataforma === 'BK' ? '/devoluciones/list' : '/devoluciones-rider/list'], {
                queryParams: {
                  timestamp: timestamp,
                  data: this.encryptService.encryptData(res[0])
                }
              });
              this.loading = false;
            },
            err => {
              console.log(err);
              this.loading = false;

            });

        break;
      case 'SOLICITUD_REVISION_TRANSFERENCIA':
        this.loading = true;

        const obtenerCorredorPorId = tarea.plataforma === 'BK' ? this.corredorService.obtenerCorredorPorId(tarea.id):
        this.riderService.obtenerRiderPorId(tarea.id);

        const petitionsCorredor = forkJoin([obtenerCorredorPorId]);
        petitionsCorredor
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.router.navigate([tarea.plataforma === 'BK' ? '/corredores/list' : '/riders/list'], {
                queryParams: {
                  timestamp: timestamp,
                  data: this.encryptService.encryptData(res[0])
                }
              });
              this.loading = false;
            },
            err => {
              console.log(err);
              this.loading = false;

            });

        break;

    }
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutAppComponent } from './layout-app/layout-app.component';
import {AuthSuperadminGuard} from './guards/auth-superadmin.guard';
import {AuthSoporteGuard} from './guards/auth-soporte.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutAppComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'agenda',
        loadChildren: () => import('./modules/general/agenda/agenda.module').then(m => m.AgendaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'calificacion',
        loadChildren: () => import('./modules/bikerace/calificacion/calificacion.module').then(m => m.CalificacionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'campos-adicionales',
        loadChildren: () => import('./modules/bikerace/campo-adicional/campo-adicional.module').then(m => m.CampoAdicionalModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/bikerace/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'dashboard-rider',
        loadChildren: () => import('./modules/rider-ticket/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'factura',
        loadChildren: () => import('./modules/general/factura/factura.module').then(m => m.FacturaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'auspiciador',
        loadChildren: () => import('./modules/bikerace/auspiciador/auspiciador.module').then(m => m.AuspiciadorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'cliente',
        loadChildren: () => import('./modules/bikerace/cliente/cliente.module').then(m => m.ClienteModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'medios-pago',
        loadChildren: () => import('./modules/bikerace/medios-pago/medios-pago.module').then(m => m.MediosPagoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'medios-pago-rider',
        loadChildren: () => import('./modules/rider-ticket/medios-pago/medios-pago.module').then(m => m.MediosPagoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'auspiciador-rider',
        loadChildren: () => import('./modules/rider-ticket/auspiciador/auspiciador.module').then(m => m.AuspiciadorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'cliente-rider',
        loadChildren: () => import('./modules/rider-ticket/cliente/cliente.module').then(m => m.ClienteModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'menus',
        loadChildren: () => import('./modules/general/menu/menu.module').then(m => m.MenuModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'sub-menus',
        loadChildren: () => import('./modules/general/sub-menu/sub-menu.module').then(m => m.SubMenuModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'eventos-estadisticas',
        loadChildren: () => import('./modules/bikerace/eventos-estadisticas/eventos-estadisticas.module').then(m => m.EventosEstadisticasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'eventos-estadisticas-rider',
        loadChildren: () => import('./modules/rider-ticket/eventos-estadisticas/eventos-estadisticas.module').then(m => m.EventosEstadisticasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'devoluciones-estatisticas',
        loadChildren: () => import('./modules/bikerace/devoluciones-estadisticas/devoluciones-estadisticas.module').then(m => m.DevolucionesEstadisticasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'usuarios-deportistas',
        loadChildren: () => import('./modules/general/usuario-deportista/usuario-deportista.module').then(m => m.UsuarioDeportistaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'adicionales',
        loadChildren: () => import('./modules/bikerace/adicional/adicional.module').then(m => m.AdicionalModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'adicionales-rider',
        loadChildren: () => import('./modules/rider-ticket/adicional/adicional.module').then(m => m.AdicionalModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'campos',
        loadChildren: () => import('./modules/bikerace/campo/campo.module').then(m => m.CampoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'campos-rider',
        loadChildren: () => import('./modules/rider-ticket/campo/campo.module').then(m => m.CampoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'evento-adicionales',
        loadChildren: () => import('./modules/bikerace/evento-adicionales/evento-adicionales.module').then(m => m.EventoAdicionalesModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'evento-campos-adicionales',
        loadChildren: () => import('./modules/bikerace/evento-campos-adicionales/evento-campos-adicionales.module').then(m => m.EventoCamposAdicionalesModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'evento-adicionales-rider',
        loadChildren: () => import('./modules/rider-ticket/evento-adicionales/evento-adicionales.module').then(m => m.EventoAdicionalesModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'contactos',
        loadChildren: () => import('./modules/general/contacto/contacto.module').then(m => m.ContactoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'usuarios-organizadores',
        loadChildren: () => import('./modules/general/usuario-organizador/usuario-organizador.module').then(m => m.UsuarioOrganizadorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'tipos-adjuntos',
        loadChildren: () => import('./modules/general/tipo-adjunto/tipo-adjunto.module').then(m => m.TipoAdjuntoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'usuarios-administradores',
        loadChildren: () => import('./modules/general/usuario-administrador/usuario-administrador.module').then(m => m.UsuarioAdministradorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'categorias',
        loadChildren: () => import('./modules/bikerace/categoria/categoria.module').then(m => m.CategoriaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'categorias-rider',
        loadChildren: () => import('./modules/rider-ticket/categoria/categoria.module').then(m => m.CategoriaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'corredores',
        loadChildren: () => import('./modules/bikerace/corredor/corredor.module').then(m => m.CorredorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'dor-ocupadas',
        loadChildren: () => import('./modules/rider-ticket/dor-ocupadas/dor-ocupadas.module').then(m => m.DorOcupadasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'riders',
        loadChildren: () => import('./modules/rider-ticket/rider/rider.module').then(m => m.RiderModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'eventos',
        loadChildren: () => import('./modules/bikerace/evento/evento.module').then(m => m.EventoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'eventos-rider',
        loadChildren: () => import('./modules/rider-ticket/evento/evento.module').then(m => m.EventoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'campeonatos',
        loadChildren: () => import('./modules/bikerace/campeonato/campeonato.module').then(m => m.CampeonatoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'campeonatos-rider',
        loadChildren: () => import('./modules/rider-ticket/campeonato/campeonato.module').then(m => m.CampeonatoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'resultados',
        loadChildren: () => import('./modules/bikerace/resultado/resultado.module').then(m => m.ResultadoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'resultados-rider',
        loadChildren: () => import('./modules/rider-ticket/resultado/resultado.module').then(m => m.ResultadoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'transaccion-adicionales',
        loadChildren: () => import('./modules/bikerace/transaccion-adicional/transaccion-adicional.module').then(m => m.TransaccionAdicionalModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'transaccion-adicionales-rider',
        loadChildren: () => import('./modules/rider-ticket/transaccion-adicional/transaccion-adicional.module').then(m => m.TransaccionAdicionalModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'transacciones',
        loadChildren: () => import('./modules/bikerace/transaccion/transaccion.module').then(m => m.TransaccionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'transacciones-rider',
        loadChildren: () => import('./modules/rider-ticket/transaccion/transaccion.module').then(m => m.TransaccionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'tuupos',
        loadChildren: () => import('./modules/bikerace/tuu-pos/tuu-pos.module').then(m => m.TuuPosModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'tuupos-rider',
        loadChildren: () => import('./modules/rider-ticket/tuu-pos/tuu-pos.module').then(m => m.TuuPosModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'solicitudes-cambio-categoria',
        loadChildren: () => import('./modules/bikerace/solicitud-cambio-categoria/solicitud-cambio-categoria.module').then(m => m.SolicitudCambioCategoriaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'solicitudes-cambio-categoria-rider',
        loadChildren: () => import('./modules/rider-ticket/solicitud-cambio-categoria/solicitud-cambio-categoria.module').then(m => m.SolicitudCambioCategoriaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'gastos',
        loadChildren: () => import('./modules/bikerace/gasto/gasto.module').then(m => m.GastoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'evento-gastos',
        loadChildren: () => import('./modules/bikerace/evento-gastos/evento-gastos.module').then(m => m.EventoGastosModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'participantes',
        loadChildren: () => import('./modules/bikerace/participante/participante.module').then(m => m.ParticipanteModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'porcentaje-ganancias',
        loadChildren: () => import('./modules/bikerace/porcentaje-ganancia/porcentaje-ganancia.module').then(m => m.PorcentajeGananciaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'gastos-rider',
        loadChildren: () => import('./modules/rider-ticket/gasto/gasto.module').then(m => m.GastoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'asignacion-numeros',
        loadChildren: () => import('./modules/bikerace/asignacion-tag-dor/asignacion-tag-dor.module').then(m => m.AsignacionTagDorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'asignacion-numeros-riders',
        loadChildren: () => import('./modules/rider-ticket/asignacion-tag-dor/asignacion-tag-dor.module').then(m => m.AsignacionTagDorModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'noticias',
        loadChildren: () => import('./modules/general/noticia/noticia.module').then(m => m.NoticiaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'metodos-pago',
        loadChildren: () => import('./modules/bikerace/metodos-pago/metodos-pago.module').then(m => m.MetodosPagoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'metodos-pago-rider',
        loadChildren: () => import('./modules/rider-ticket/metodos-pago/metodos-pago.module').then(m => m.MetodosPagoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'cotizaciones',
        loadChildren: () => import('./modules/general/cotizacion/cotizacion.module').then(m => m.CotizacionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'inscripciones',
        loadChildren: () => import('./modules/bikerace/inscripciones/inscripciones.module').then(m => m.InscripcionesModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'inscripciones-rider',
        loadChildren: () => import('./modules/rider-ticket/inscripciones/inscripciones.module').then(m => m.InscripcionesModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'ganancias-estadisticas',
        loadChildren: () => import('./modules/bikerace/ganancias-estadisticas/ganancias-estadisticas.module').then(m => m.GananciasEstadisticasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'ganancias-estadisticas-rider',
        loadChildren: () => import('./modules/rider-ticket/ganancias-estadisticas/ganancias-estadisticas.module').then(m => m.GananciasEstadisticasModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'accesos',
        loadChildren: () => import('./modules/general/accesos/accesos.module').then(m => m.AccesosModule),
        data: {extraParameter: 'sub-menu-1-1'},
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'emails',
        loadChildren: () => import('./modules/bikerace/email/email.module').then(m => m.EmailModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'sms',
        loadChildren: () => import('./modules/bikerace/sms/sms.module').then(m => m.SmsModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'emails-rider',
        loadChildren: () => import('./modules/rider-ticket/email/email.module').then(m => m.EmailModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'sms-rider',
        loadChildren: () => import('./modules/rider-ticket/sms/sms.module').then(m => m.SmsModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'bancos',
        loadChildren: () => import('./modules/general/banco/banco.module').then(m => m.BancoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'tipos-cuenta',
        loadChildren: () => import('./modules/general/tipos-cuenta/tipos-cuenta.module').then(m => m.TiposCuentaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'retiros',
        loadChildren: () => import('./modules/bikerace/retiro/retiro.module').then(m => m.RetiroModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'retiros-rider',
        loadChildren: () => import('./modules/rider-ticket/retiro/retiro.module').then(m => m.RetiroModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'comisiones',
        loadChildren: () => import('./modules/general/comision/comision.module').then(m => m.ComisionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'notificaciones',
        loadChildren: () => import('./modules/bikerace/notificacion/notificacion.module').then(m => m.NotificacionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'cupones',
        loadChildren: () => import('./modules/bikerace/cupon/cupon.module').then(m => m.CuponModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'cupones-rider',
        loadChildren: () => import('./modules/rider-ticket/cupon/cupon.module').then(m => m.CuponModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'devoluciones',
        loadChildren: () => import('./modules/bikerace/devolucion/devolucion.module').then(m => m.DevolucionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'desafios',
        loadChildren: () => import('./modules/bikerace/desafio/desafio.module').then(m => m.DesafioModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'desafios-rider',
        loadChildren: () => import('./modules/rider-ticket/desafio/desafio.module').then(m => m.DesafioModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'devoluciones-rider',
        loadChildren: () => import('./modules/rider-ticket/devolucion/devolucion.module').then(m => m.DevolucionModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'entrega-kit',
        loadChildren: () => import('./modules/bikerace/entrega-kit/entrega-kit.module').then(m => m.EntregaKitModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'entrega-kit-rider',
        loadChildren: () => import('./modules/rider-ticket/entrega-kit/entrega-kit.module').then(m => m.EntregaKitModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'asistencia',
        loadChildren: () => import('./modules/bikerace/asistencia-evento/asistencia-evento.module').then(m => m.AsistenciaEventoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'asistencia-rider',
        loadChildren: () => import('./modules/rider-ticket/asistencia-evento/asistencia-evento.module').then(m => m.AsistenciaEventoModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSoporteGuard]
      },
      {
        path: 'publicidad',
        loadChildren: () => import('./modules/bikerace/publicidad/publicidad.module').then(m => m.PublicidadModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'publicidad-rider',
        loadChildren: () => import('./modules/rider-ticket/publicidad/publicidad.module').then(m => m.PublicidadModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'talla-tricota',
        loadChildren: () => import('./modules/general/talla-tricota/talla-tricota.module').then(m => m.TallaTricotaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },

      {
        path: 'evento-gastos-rider',
        loadChildren: () => import('./modules/rider-ticket/evento-gastos/evento-gastos.module').then(m => m.EventoGastosModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'participantes-rider',
        loadChildren: () => import('./modules/rider-ticket/participante/participante.module').then(m => m.ParticipanteModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },
      {
        path: 'porcentaje-ganancias-rider',
        loadChildren: () => import('./modules/rider-ticket/porcentaje-ganancia/porcentaje-ganancia.module').then(m => m.PorcentajeGananciaModule),
        data: { extraParameter: 'sub-menu-1-1' },
        canActivate: [AuthSuperadminGuard]
      },

    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/general/auth/auth.module').then(m => m.AuthModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
